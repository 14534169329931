<template>
    <div v-if="category" :class="{'category-pill': true, white: color === 'white', selected: selected}">
        <button v-if="button" @click="onclick">
            <span>{{ t(`resources.categories.${category}`) }}</span>
        </button>
        <a v-else :href="link ?? `/resources/search?category=${category}`">
            <span>{{ t(`resources.categories.${category}`) }}</span>
        </a>
    </div>
</template>

<script lang="ts" setup>
    import {useTranslation} from '#imports';
    defineProps<{
        category: string | null | undefined;
        color?: 'primary' | 'white';
        selected?: boolean;
        link?: string;
        button?: boolean;
        onclick?: () => void;
    }>();

    const {t} = useTranslation();
</script>

<style lang="scss">
    .category-pill {
        padding: 4px 12px;

        a,
        button {
            display: inline-block;
            color: var(--joy-color-primary-50);
            font-size: var(--joy-font-size-primary-400);

            text-align: center;

            transition: color var(--joy-transition-duration-default) var(--joy-transition-timing-function);
            position: relative;

            background: transparent;
            span {
                position: relative;
                z-index: 2;
            }
            &::after {
                border: 1px solid var(--joy-color-primary-50);
                border-radius: 50px;

                background-color: white;
                content: '';
                z-index: 1;
                position: absolute;
                top: -4px;
                bottom: -4px;
                right: -12px;
                left: -12px;
                transition: background-color var(--joy-transition-duration-default) var(--joy-transition-timing-function);
            }
            &:hover {
                color: white;
                &::after {
                    background-color: var(--joy-color-primary-50);
                }
            }
        }

        &.selected {
            a,
            button {
                color: white;
                &::after {
                    background-color: var(--joy-color-primary-50);
                }
                &:hover::after {
                    background-color: var(--joy-color-primary-70);
                }
            }
        }
        &.white {
            a,
            button {
                color: white;
                &::after {
                    border: 1px solid white;

                    background-color: var(--joy-color-primary-50);
                }
                &:hover {
                    color: var(--joy-color-primary-50);
                    &::after {
                        background-color: white;
                    }
                }
                &.selected::after {
                    background-color: var(--joy-color-neutral-30);
                }
            }
        }
    }
</style>
